<template>
  <div>
    <div class="title">
      Deposit
      {{
        balanceType == 1
          ? "To Balance Casino Online"
          : balanceType == 2
          ? "To Balance Sport Book"
          : balanceType == 3
          ? "To Balance Binary Option"
          : "To Balance Fish Shooter"
      }}
    </div>
    <ValidationObserver v-slot="{ invalid }">
      <form id="form-deposit" class="form-horizontal" style="padding: 20px; padding-bottom: 0;">
        <!-- <ValidationProvider rules="required" v-slot="{ errors }" tag="div">
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="col-xs-12">
              <div class="input-group">
                <span class="input-group-addon"><i class="gi gi-money"></i></span>
                <select
                  v-model="balanceType"
                  name="deposit-type"
                  class="form-control input-lg"
                  size="1"
                  disabled
                >
       
                  <option value="1">Casino Online</option>
                  <option value="2">Sport Book</option>
                  <option value="3">Binary Option</option>
                </select>
              </div>
              <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
            </div>
          </div>
        </ValidationProvider> -->
        <ValidationProvider rules="required|min_value:50" v-slot="{ errors }" tag="div">
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="col-xs-12">
              <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-hashtag"></i></span>
                <input
                  v-model.number="amount"
                  type="text"
                  name="deposit-amount"
                  class="form-control input-lg"
                  placeholder="Amount"
                />
              </div>
              <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
            </div>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required|min:6|max:12" v-slot="{ errors }" tag="div">
          <div class="form-group" :class="{ 'has-error': errors[0] }">
            <div class="col-xs-12">
              <div class="input-group">
                <span class="input-group-addon"><i class="gi gi-asterisk"></i></span>
                <input
                  v-model="password"
                  type="password"
                  name="deposit-password"
                  class="form-control input-lg"
                  placeholder="Current password"
                />
              </div>
              <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
            </div>
          </div>
        </ValidationProvider>
        <div class="form-group"></div>
        <div class="form-group">
          <div class="col-xs-12 text-center">
            <button
              type="button"
              class="btn btn-sm btn-info"
              :disabled="invalid"
              @click="handleDeposit"
            >
              Deposit
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import recaptcha from "@/mixins/recaptcha";
import { mapGetters } from "vuex";
export default {
  name: "DepositForm",
  props: {
    // accountId: String,
    balanceType: Number
  },
  data: () => ({
    amount: "",
    password: ""
  }),
  computed: {
    ...mapGetters({
      Balance: "dashboard/Balance"
    })
  },
  methods: {
    async handleDeposit() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("deposit");
      if (token) {
        if (this.Balance["EUSD"] >= this.amount) {
          this.$store.dispatch("wallet/req_depositGame", {
            amount: this.amount,
            password: this.password,
            typeDeposit: this.balanceType,
            token: token
          });
        } else {
          this.$toast.error("Your Balance Is Not Enough ", "Deposit Failed!");
        }
      } else {
        this.$toast.error("Oops!Some Things went wrong .Please Deposit Again ", "Deposit Failed!");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-deposit {
  &__recaptcha {
    display: flex;
    justify-content: center;
  }
}
.title {
  text-align: center;
  background: #1e90ff;
  padding: 5px 0;
  font-size: 15px;
  font-weight: 700;
  color: white;
}
</style>
