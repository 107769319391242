import baseAPI from './base';

const resource = 'subaccount';

const add = async (phone, password, token) => {
  const response = await baseAPI().post(`${resource}/add-subaccount`, { phone, password, token });
  return response.data;
};

const list = async (subaccount, status) => {
  const response = await baseAPI().post(`${resource}/manage-subaccount`, {
    subaccount,
    status,
  });
  return response.data.data;
};

const lock = async (id) => {
  const response = await baseAPI().get(`${resource}/isblock-subaccount/${id}`);
  return response.data;
};

const updatePassword = async (account, currentPass, newPass) => {
  const response = await baseAPI().post(`${resource}/update-password`, {
    account,
    OldPassword: currentPass,
    Password: newPass,
    ConfirmPassword: newPass,
  });
  return response.data;
};

const deposit = async (typeDeposit, amount, password, token) => {
  const response = await baseAPI().post(`${resource}/deposit-subaccount`, {
    typeDeposit,
    amount,
    password,
    token,
  });
  return response.data;
};

const withdraw = async (typeWithdraw, amount, password, token) => {
  const response = await baseAPI().post(`${resource}/withdraw-subaccount`, {

    typeWithdraw,
    amount,
    password,
    token,
  });
  return response.data;
};

const walletHistory = async (page) => {
  const response = await baseAPI().post(`${resource}/wallet-history`, {
    page,
  });
  return response.data.data;
};

const gameHistory = async () => {
  const response = await baseAPI().post(`${resource}/game-history`);
  return response.data.data;
};

const casinoSetting = async (account, amount) => {
  const response = await baseAPI().post(`${resource}/set-user-max-win`, {
    account,
    MaxWinning: amount,
  });

  return response;
};

export default {
  add,
  list,
  lock,
  updatePassword,
  deposit,
  withdraw,
  walletHistory,
  gameHistory,
  casinoSetting,
};
